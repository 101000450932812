import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { CardService } from '../../components/molecules/Card/CardService'
import { LayoutService } from '../../components/organisms/Layout/LayoutService'

const cardList = [
  {
    serviceName: 'プロジェクトファイナンス',
    imagePathPc: '/service/img/financial/financial1.png',
    imagePathSp: '/service/img/financial/financial1sp.png',
    title: '不動産融資をトータルサポート',
    content:
      '主として不動産会社や建設会社の様に、業として不動産売買を行なう方々に向けた用地取得費や不動産開発資金のご融資を提案します。',
    groupList: [
      {
        text: '株式会社クレイリッシュ',
        url: 'https://901901.jp/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '不動産担保ローン',
    imagePathPc: '/service/img/financial/financial2.png',
    imagePathSp: '/service/img/financial/financial2sp.png',
    title: '経験豊富なスタッフが迅速な融資実現をサポート',
    content:
      '主として事業者を対象とし、設備資金・運転資金を低金利で調達できるローンとなります。',
    groupList: [
      {
        text: '株式会社クレイリッシュ',
        url: 'https://901901.jp/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '手形・でんさい割引',
    imagePathPc: '/service/img/financial/financial3.png',
    imagePathSp: '/service/img/financial/financial3sp.png',
    title: '独自ノウハウで手形割引を支援します',
    content:
      '20年以上の手形割引の実績があり、様々な状況の環境の企業様が発行した手形であっても、割引を提案します。さらに、手形割引アプリ「手形割引王」をご活用頂くことで、面倒な手形割引の申し込みが迅速に対応できます。',
    groupList: [{ text: '株式会社クレイリッシュ', url: 'https://901901.jp/' }],
    columnNumber: 2,
  },
  {
    serviceName: 'その他',
    imagePathPc: '/service/img/financial/financial4.png',
    imagePathSp: '/service/img/financial/financial4sp.png',
    title: '様々なケースにご対応可能な体制を構築',
    content:
      '多種多様なご提案が可能であり、日本全国より資金需要に係るご相談を頂いております。',
    groupList: [{ text: '株式会社クレイリッシュ', url: 'https://901901.jp/' }],
    columnNumber: 2,
  },
]

const Page = () => {
  return (
    <LayoutService
      heading="フィナンシャル事業"
      headingEnglish={ENGLISH_TITLE.Financial}
      catchCopy="企業の未来を創造するフィナンシャルパートナーとして雨の日に傘を貸し、誠実かつ謙虚にお客様に向き合い、“いざ”というときに第一に選ばれるファイナンサーを目指します。"
    >
      <div className="bg-white pc:px-8">
        <section className="pc:py-12">
          <ul className="grid gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2">
            {cardList.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
        </section>
      </div>
    </LayoutService>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEO title="フィナンシャル事業" url="/service/financial" />
)
